import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { APPLICATION_SUBDOMAIN } from '../constants';
import { useLocalizedZenamuLandingPageHost } from '../hooks/useLocalizedZenamuLandingPageHost';
import { APP_TAB_KEY_OFFICE, SETTINGS_TAB_KEY_SUBSCRIPTION_ACTIVATION } from '../router/constants';
import { AlertMui } from './AlertMui';
import { LinkText } from './LinkText';

interface FreeTariffDoodletipProps {
    domain: string;
    withLinkToAdminSubscription?: boolean;
}

export const FreeTariffDoodletip = ({ domain, withLinkToAdminSubscription = true }: FreeTariffDoodletipProps) => {
    const { t } = useTranslation();
    const landingPageHost = useLocalizedZenamuLandingPageHost();

    return (
        <AlertMui
            severity="error"
            title={
                <Trans
                    i18nKey="subscriptionNs:tariffInfo.freeTariffUpgradeTitle"
                    components={{
                        freeTariffUpgradeTitleLink: <LinkText to={`${landingPageHost}/pricing/`} targetBlank />,
                    }}
                />
            }
        >
            {withLinkToAdminSubscription && (
                <Trans
                    i18nKey="subscriptionNs:tariffInfo.freeTariffUpgradeDescription"
                    components={{
                        freeTariffUpgradeDescriptionLink: (
                            <LinkText
                                to={`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${domain}/${SETTINGS_TAB_KEY_SUBSCRIPTION_ACTIVATION}`}
                                title={t('subscriptionNs:tariffInfo.freeTariffUpgradeDescriptionLink')}
                            />
                        ),
                    }}
                />
            )}
        </AlertMui>
    );
};
