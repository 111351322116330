import { appLanguages, stripeTariffs, tariffs } from '@fyooga/codebook';

import { TariffT } from '../types/subscription';

export const isTariffFree = (tariffName?: string): boolean => {
    return tariffName === tariffs.FREE.name;
};

export const isTariffTrial = (tariffName: string): boolean => {
    return tariffName.includes('TRIAL');
};

export const isFreeTariffActive = (organisationTariff: TariffT) => {
    const { lecturesLimit, name } = organisationTariff;
    return isTariffFree(name) && lecturesLimit > 0;
};

const getTariffNameForStripeOrThePay = (langCode: string, tariffName: string) => {
    return {
        name: tariffs[tariffName][langCode].name,
        description: tariffs[tariffName][langCode].description,
    };
};

export const getTariffNameAndDescriptionByCurrentLanguage = (tariffName: string, lang: string) => {
    // FIXME for now we have only two languages
    if (lang === appLanguages.CS) {
        return getTariffNameForStripeOrThePay('cs', tariffName);
    }
    if (lang === appLanguages.SK) {
        return getTariffNameForStripeOrThePay('sk', tariffName);
    }
    if (lang === appLanguages.HU) {
        return getTariffNameForStripeOrThePay('hu', tariffName);
    }
    if (lang === appLanguages.PL) {
        return getTariffNameForStripeOrThePay('pl', tariffName);
    }
    return getTariffNameForStripeOrThePay('en', tariffName);
};

export const isDeprecatedFreelancerTariff = (tariffName?: string): boolean => {
    if (tariffName) {
        return (
            tariffName === tariffs.FREELANCER_2021_MONTH_CZK.name ||
            tariffName === tariffs.FREELANCER_2021_YEAR_CZK.name ||
            tariffName === tariffs.FREELANCER_2021_MONTH_EUR.name ||
            tariffName === tariffs.FREELANCER_2021_YEAR_EUR.name ||
            tariffName === tariffs.FREELANCER_2021_MONTH_USD.name ||
            tariffName === tariffs.FREELANCER_2021_YEAR_USD.name ||
            tariffName === stripeTariffs.FREELANCER_2024_MONTH_COMPATIBILITY_CZK.name ||
            tariffName === stripeTariffs.FREELANCER_2024_YEAR_COMPATIBILITY_CZK.name ||
            tariffName === stripeTariffs.FREELANCER_2024_MONTH_COMPATIBILITY_EUR.name ||
            tariffName === stripeTariffs.FREELANCER_2024_YEAR_COMPATIBILITY_EUR.name ||
            tariffName === stripeTariffs.FREELANCER_2024_MONTH_COMPATIBILITY_USD.name ||
            tariffName === stripeTariffs.FREELANCER_2024_YEAR_COMPATIBILITY_USD.name ||
            tariffName === stripeTariffs.FREELANCER_2024_MONTH_COMPATIBILITY_HUF.name ||
            tariffName === stripeTariffs.FREELANCER_2024_YEAR_COMPATIBILITY_HUF.name ||
            tariffName === stripeTariffs.FREELANCER_2024_MONTH_COMPATIBILITY_PLN.name ||
            tariffName === stripeTariffs.FREELANCER_2024_YEAR_COMPATIBILITY_PLN.name ||
            tariffName === stripeTariffs.FREELANCER_2024_MONTH_COMPATIBILITY_GBP.name ||
            tariffName === stripeTariffs.FREELANCER_2024_YEAR_COMPATIBILITY_GBP.name
        );
    }

    return false;
};

export const isDeprecatedStudioTariff = (tariffName?: string): boolean => {
    if (tariffName) {
        return (
            tariffName === tariffs.STUDIO_2021_MONTH_CZK.name ||
            tariffName === tariffs.STUDIO_2021_YEAR_CZK.name ||
            tariffName === tariffs.STUDIO_2021_MONTH_EUR.name ||
            tariffName === tariffs.STUDIO_2021_YEAR_EUR.name ||
            tariffName === tariffs.STUDIO_2021_MONTH_USD.name ||
            tariffName === tariffs.STUDIO_2021_YEAR_USD.name ||
            tariffName === stripeTariffs.STUDIO_2024_MONTH_COMPATIBILITY_CZK.name ||
            tariffName === stripeTariffs.STUDIO_2024_YEAR_COMPATIBILITY_CZK.name ||
            tariffName === stripeTariffs.STUDIO_2024_MONTH_COMPATIBILITY_EUR.name ||
            tariffName === stripeTariffs.STUDIO_2024_YEAR_COMPATIBILITY_EUR.name ||
            tariffName === stripeTariffs.STUDIO_2024_MONTH_COMPATIBILITY_USD.name ||
            tariffName === stripeTariffs.STUDIO_2024_YEAR_COMPATIBILITY_USD.name ||
            tariffName === stripeTariffs.STUDIO_2024_MONTH_COMPATIBILITY_HUF.name ||
            tariffName === stripeTariffs.STUDIO_2024_YEAR_COMPATIBILITY_HUF.name ||
            tariffName === stripeTariffs.STUDIO_2024_MONTH_COMPATIBILITY_PLN.name ||
            tariffName === stripeTariffs.STUDIO_2024_YEAR_COMPATIBILITY_PLN.name ||
            tariffName === stripeTariffs.STUDIO_2024_MONTH_COMPATIBILITY_GBP.name ||
            tariffName === stripeTariffs.STUDIO_2024_YEAR_COMPATIBILITY_GBP.name
        );
    }

    return false;
};
