import Alert from '@mui/material/Alert';
import { AlertProps } from '@mui/material/Alert/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import { ReactNode } from 'react';

type AlertMuiCustomProps = Pick<AlertProps, 'severity' | 'icon'>;
interface AlertMuiProps extends AlertMuiCustomProps {
    children: ReactNode;
    title?: ReactNode;
    marginTop?: number;
    marginBottom?: number;
}

export const AlertMui = ({ severity, icon, title, marginTop, marginBottom = 15, children }: AlertMuiProps) => {
    return (
        <Alert
            severity={severity}
            icon={icon}
            style={{ marginBottom: `${marginBottom}px`, marginTop: marginTop ? `${marginTop}px` : 0 }}
        >
            {title && <AlertTitle>{title}</AlertTitle>}
            {children}
        </Alert>
    );
};
