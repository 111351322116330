import { frequencyPeriod as frequencyPeriodType, lectureInstanceStatus, reservationStates } from '@fyooga/codebook';
import {
    addDays,
    addMonths,
    getDay,
    isAfter,
    isBefore,
    parseISO,
    startOfDay,
    subDays,
    subHours,
    subMinutes,
    subSeconds,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import hexToRgba from 'hex-to-rgba';
import _ from 'lodash';
import moment from 'moment';

import { appLocales } from '../constants';
import history from '../history';
import { LittleCalendarEventMetaT } from '../types/calendar';
import { LectureInstanceStatusValues, LectureT } from '../types/lectureInstance';
import { isDarkColor } from './colors';
import { startOfWeekFromMonday } from './dateLocaleLib/datetime';

const { padStart } = _;
const LIGHT_FOREGROUND_COLOR = '#efefef';
export const DARK_FOREGROUND_COLOR = 'rgba(0, 0, 0, 0.5)';

export const DAYS_IN_WEEK = 7;

export const LOGIN_QUERY_PARAM_FROM_LOCATION = 'fromLocation';
export const REGISTRATION_QUERY_PARAM_FROM_DOMAIN = 'fromDomain';
export const PUBLIC_CALENDAR_QUERY_PARAM_VIEW = 'publicView';
export const CALENDAR_QUERY_PARAM_FROM = 'from';
export const CALENDAR_QUERY_PARAM_SELECTED_TERM = 'selectedTerm';

type OfficeConfigT = {
    timezone: string;
    openingHours: number;
    closingTime: number;
    closeLogoutBeforeAllowedWhenever: boolean;
    closeLogoutBeforeHours: number;
    closeReservationBeforeAllowedWhenever: boolean;
    openReservationBeforeDays: number;
    closeReservationBeforeHours: number;
    closeReservationBeforeMinutes: number;
    openReservationBeforeAllowedWhenever: boolean;
    freeCourseSpotsBookAfterCourseAllowed: boolean;
    freeCourseSpotsBookBeforeDays: number;
    openReservationBeforeHours: number;
    openReservationBeforeMinutes: number;
    courseOptOutBeforeMinutesAllowed: boolean;
    courseOptOutBeforeMinutes: number;
    courseOptOutValidityDays: number;
};

const getAmHour = (hour: number) => {
    return `${hour} AM`;
};

const getPmHour = (hour: number) => {
    return `${hour} PM`;
};

export const addMinutesStringToHour = (hour: number, userAppLocale: string): string => {
    if (userAppLocale === appLocales.EN) {
        if (hour === 12) {
            return getPmHour(hour);
        }
        if (hour === 24) {
            return getAmHour(12);
        }
        return hour < 12 ? `${hour} AM` : `${hour - 12} PM`;
    }
    return `${padStart(`${hour}`, 2, '0')}:00`;
};

/**
 */
export function isFutureLecture(lectureData: LectureT): boolean {
    return !lectureData.passed;
}

/**
 * @param lecture
 */
export function isPassedLectureForAdministration(lecture: LectureT): boolean {
    const { passed } = lecture;
    return passed;
}

export const getUnixTimestamp = (date: Date): number => {
    // eslint-disable-next-line no-bitwise
    return (date.getTime() / 1000) | 0;
};

export const getDateFromUnixTimestamp = (value: number): Date => {
    return new Date(value * 1000);
};

export const clearCalendarQueryParams = (queryParams: URLSearchParams) => {
    queryParams.delete(CALENDAR_QUERY_PARAM_FROM);
    history.push({ search: queryParams.toString() });
};

export const stepBackwardWithQueryParameter = (startDay: Date, queryParams: URLSearchParams, days = 7) => {
    const backwardWeekStart = subDays(startDay, days);
    queryParams.set(CALENDAR_QUERY_PARAM_FROM, `${getUnixTimestamp(backwardWeekStart)}`);
    history.push({ search: queryParams.toString() });
};

export const stepForwardWithQueryParameter = (startDay: Date, queryParams: URLSearchParams, days = 7) => {
    const forwardedWeekStart = addDays(startDay, days);
    queryParams.set(CALENDAR_QUERY_PARAM_FROM, `${getUnixTimestamp(forwardedWeekStart)}`);
    history.push({ search: queryParams.toString() });
};

export const setCalendarWeekByDate = (date: Date, queryParams: URLSearchParams, setCurrent = false) => {
    if (setCurrent) {
        clearCalendarQueryParams(queryParams);
    } else {
        queryParams.set(CALENDAR_QUERY_PARAM_FROM, `${getUnixTimestamp(startOfWeekFromMonday(date))}`);
        history.push({ search: queryParams.toString() });
    }
};

export const setCalendarFromCurrentDayByDate = (date: Date, queryParams: URLSearchParams) => {
    queryParams.set(CALENDAR_QUERY_PARAM_FROM, `${getUnixTimestamp(startOfDay(date))}`);
    history.push({ search: queryParams.toString() });
};

export const fillCurrentWeek = (dateRelevantForWeekStart: Date) => {
    const currentWeekDaysArray = [];
    const weekStartWithTimezone = startOfWeekFromMonday(dateRelevantForWeekStart);
    currentWeekDaysArray.push(startOfWeekFromMonday(weekStartWithTimezone));
    for (let i = 1; i <= DAYS_IN_WEEK - 1; i++) {
        const addedDays = addDays(startOfWeekFromMonday(weekStartWithTimezone), i);
        currentWeekDaysArray.push(addedDays);
    }
    return currentWeekDaysArray;
};

export const getCalendarCurrentWeek = (dateRelevantForWeekStart: Date, isPublicAndListView = false) => {
    const weekStartForBrowserWithTimezone = isPublicAndListView
        ? startOfDay(dateRelevantForWeekStart)
        : startOfWeekFromMonday(dateRelevantForWeekStart);

    const weekEndForBrowserWithTimezoneValue = subSeconds(addDays(weekStartForBrowserWithTimezone, 7), 1);
    // shift by timezone offset for correct UTC query
    const weekStartGraphQlQuery = subMinutes(
        weekStartForBrowserWithTimezone,
        weekStartForBrowserWithTimezone.getTimezoneOffset(),
    );
    const weekEndGraphQlQuery = subSeconds(addDays(weekStartGraphQlQuery, 7), 1);

    // set current week
    const currentWeekDaysArray = fillCurrentWeek(dateRelevantForWeekStart);

    return {
        weekStartForBrowserWithTimezone,
        weekEndForBrowserWithTimezoneValue,
        weekStartGraphQlQuery,
        weekEndGraphQlQuery,
        currentWeekDaysArray,
    };
};

const getCalendarCurrentFromQueryParams = (
    queryFrom: string | null,
    queryParams: URLSearchParams,
    isPublicAndListView = false,
) => {
    const queryFromDate = getDateFromUnixTimestamp(Number(queryFrom));

    const isValidQueryFrom = queryFrom && queryFromDate?.getTime() > 0;

    let dateRelevantForWeekStart;
    if (isValidQueryFrom) {
        dateRelevantForWeekStart = queryFromDate;
    } else {
        dateRelevantForWeekStart = new Date();
        if (queryParams && Object.keys(queryParams).length) {
            clearCalendarQueryParams(queryParams);
        }
    }
    return dateRelevantForWeekStart;
};

export const getCalendarCurrentWeekFromQueryParams = (queryFrom: string | null, queryParams: URLSearchParams) => {
    const dateRelevantForWeekStart = getCalendarCurrentFromQueryParams(queryFrom, queryParams);
    return getCalendarCurrentWeek(dateRelevantForWeekStart);
};

export const getCalendarCurrentDayFromQueryParams = (queryFrom: string | null, queryParams: URLSearchParams) => {
    const dateRelevantForWeekStart = getCalendarCurrentFromQueryParams(queryFrom, queryParams);
    return getCalendarCurrentWeek(dateRelevantForWeekStart, true);
};

export const startOfWeekFromMondayQueryParamsUtc = (utcStartDateString: string) => {
    const dateRelevantForWeekStart = parseISO(utcStartDateString);
    const weekStartWithTimezone = startOfWeekFromMonday(dateRelevantForWeekStart);
    // shift by timezone offset for correct UTC query
    const weekStartDayQueryParam = subMinutes(weekStartWithTimezone, weekStartWithTimezone.getTimezoneOffset());
    const weekEndDayQueryParam = subSeconds(addDays(weekStartDayQueryParam, 7), 1);
    return {
        weekStartDayQueryParam,
        weekEndDayQueryParam,
    };
};

export const calculateLectureLength = (start: string, end: string) => {
    return moment(end).diff(moment(start), 'minutes');
};

/**
 *
 * @param utcLectureStart - start date in UTC date format
 */
const isBookOperationAllowedBeforeDatetime = (
    operationAllowedWhenever: boolean,
    utcLectureStart: string,
    beforeHours: number,
    beforeDays?: number,
): boolean => {
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const startTimeZoneOffset = utcToZonedTime(utcLectureStart, browserTimezone);
    if (!operationAllowedWhenever) {
        let dateWithSubtractedHours = subHours(startTimeZoneOffset, beforeHours);
        if (beforeDays) {
            dateWithSubtractedHours = subDays(dateWithSubtractedHours, beforeDays);
        }
        return isBefore(new Date(), dateWithSubtractedHours);
    }
    return true;
};

// Kdy nejpozději se dá přihlásit před začátkem lekce
export const lectureBookableBeforeClosure = (lectureStart: string, officeConfig: OfficeConfigT) => {
    const { closeReservationBeforeAllowedWhenever, closeReservationBeforeHours, closeReservationBeforeMinutes } =
        officeConfig;
    return isBookOperationAllowedBeforeDatetime(
        closeReservationBeforeAllowedWhenever,
        lectureStart,
        closeReservationBeforeHours,
        closeReservationBeforeMinutes,
    );
};

// Kdy nejpozději se dá odhlásit z termínu kurzu a obdržet za to náhradu
export const courseTermPossibleToCancelWithReplacement = (utcLectureStart: string, officeConfig: OfficeConfigT) => {
    const { courseOptOutBeforeMinutesAllowed, courseOptOutBeforeMinutes } = officeConfig;
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const startTimeZoneOffset = utcToZonedTime(utcLectureStart, browserTimezone);
    if (!courseOptOutBeforeMinutesAllowed) {
        return false;
    }
    let dateWithSubtractedMinutes = subMinutes(startTimeZoneOffset, courseOptOutBeforeMinutes);
    return isBefore(new Date(), dateWithSubtractedMinutes);
};

// Kdy nejpozději se dá odhlásit před začátkem lekce.
export const logoutFromLectureBeforeClosure = (lectureStart: string, officeConfig: OfficeConfigT) => {
    const { closeLogoutBeforeAllowedWhenever, closeLogoutBeforeHours } = officeConfig;
    const result = isBookOperationAllowedBeforeDatetime(
        closeLogoutBeforeAllowedWhenever,
        lectureStart,
        closeLogoutBeforeHours,
    );
    return result;
};

/**
 * Kdy nejdřív se dá přihlásit před začátkem lekce - kdy budou možnosti rezervací otevřeny
 * @param utcLectureStart - start date in UTC format
 */
export const lectureBookableBeforeOpen = (utcLectureStart: string, officeConfig: OfficeConfigT) => {
    const {
        openReservationBeforeAllowedWhenever,
        openReservationBeforeDays,
        openReservationBeforeHours,
        openReservationBeforeMinutes,
    } = officeConfig;
    const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const startTimeZoneOffset = utcToZonedTime(utcLectureStart, browserTimezone);
    if (!openReservationBeforeAllowedWhenever) {
        const dateWithSubtractedDays = subDays(startTimeZoneOffset, openReservationBeforeDays);
        const dateWithSubtractedDaysAndHours = subHours(dateWithSubtractedDays, openReservationBeforeHours);
        const dateWithSubtractedDaysAndHoursAndMinutes = subMinutes(
            dateWithSubtractedDaysAndHours,
            openReservationBeforeMinutes,
        );
        return isAfter(new Date(), dateWithSubtractedDaysAndHoursAndMinutes);
    }
    return true;
};

/**
 * warning: default day in week numbering is 0 for Sunday but we count days in week from 0 for monday and sunday is 6.
 * @param lectureStart
 */
export const getDayInWeekForLectureStart = (lectureStart: Date): number => {
    let dayInWeek = getDay(lectureStart);
    if (dayInWeek > 0) {
        dayInWeek -= 1;
    } else {
        dayInWeek = 6;
    }
    return dayInWeek;
};

export const getCalendarEventBorder = (
    isLectureInTheFuture: boolean,
    color: string,
    status: string,
    list: boolean = false,
): {
    borderTop: string;
    borderRight: string;
    borderBottom: string;
    borderLeft: string;
} => {
    let borderObject = {
        borderTop: '0',
        borderRight: '0',
        borderBottom: '0',
        borderLeft: '0',
    };

    if (status === lectureInstanceStatus.DRAFT) {
        borderObject = {
            borderTop: `2px solid ${color}`,
            borderRight: `2px solid ${color}`,
            borderBottom: `2px solid ${color}`,
            borderLeft: `2px solid ${color}`,
        };
    }

    if (isLectureInTheFuture && list) {
        return {
            ...borderObject,
            borderLeft: `5px solid ${color}`,
        };
    } else if (isLectureInTheFuture) {
        return borderObject;
    }

    // past...
    return {
        ...borderObject,
        borderLeft: list ? `5px solid ${color}` : `2px solid ${hexToRgba(color, '0.5')}`,
        ...(status === lectureInstanceStatus.DRAFT && {
            borderTop: `2px solid rgb(229, 229, 229)`,
            borderRight: `2px solid rgb(229, 229, 229)`,
            borderBottom: `2px solid rgb(229, 229, 229)`,
        }),
    };
};

export const getCalendarEventBackground = (
    isLectureInTheFuture: boolean,
    color: string,
    status: string,
    isCanceled: boolean,
    list: boolean = false,
): string => {
    if (isCanceled) {
        return 'rgb(229, 229, 229)';
    }
    if (status === lectureInstanceStatus.DRAFT) {
        return '#fff';
    }

    if (isLectureInTheFuture && list) {
        return hexToRgba(color, '0.3');
    } else if (isLectureInTheFuture) {
        return color;
    }

    return 'rgb(229, 229, 229)';
};

export const getLectureTextColorStyleAccordingBackground = (
    backroundColor: string,
    isLectureInTheFuture: boolean,
    isCanceled: boolean,
    status: LectureInstanceStatusValues,
): string => {
    if (status === lectureInstanceStatus.DRAFT || isCanceled) {
        return DARK_FOREGROUND_COLOR;
    }

    const titleColor = isDarkColor(backroundColor) ? LIGHT_FOREGROUND_COLOR : DARK_FOREGROUND_COLOR;

    return isLectureInTheFuture ? titleColor : DARK_FOREGROUND_COLOR;
};

export const getTextColorStyleAccordingBackground = (backroundColor: string): string => {
    return isDarkColor(backroundColor) ? LIGHT_FOREGROUND_COLOR : DARK_FOREGROUND_COLOR;
};

export const getLastFrequencyDateLecture = (
    startDate: Date,
    frequencyTimes: number,
    frequencyPeriod: 'week' | 'month',
) => {
    let lectureRepeatCount = 1;
    switch (frequencyPeriod) {
        case frequencyPeriodType.WEEK:
            lectureRepeatCount = frequencyTimes - 1;
            break;
        case frequencyPeriodType.MONTH: {
            const lastDate = addMonths(startDate, frequencyTimes - 1);
            // @ts-ignore
            const daysBetween = Math.round((lastDate - startDate) / (1000 * 60 * 60 * 24));
            lectureRepeatCount = Math.floor(daysBetween / 7);
            break;
        }
    }
    return addDays(startDate, lectureRepeatCount * 7);
};

export const addEventToCalendarDay = (
    day: Date,
    event: LittleCalendarEventMetaT,
    dailyMap: Map<number, Array<LittleCalendarEventMetaT>>,
) => {
    const dayUnixTimestamp = getUnixTimestamp(day);
    const dayEvents = dailyMap.get(dayUnixTimestamp);
    if (dayEvents) {
        dayEvents.push(event);
    } else {
        dailyMap.set(dayUnixTimestamp, [event]);
    }
};

export const addEventToCalendarWeek = (
    weekStart: Date,
    event: LittleCalendarEventMetaT,
    weeklyMap: Map<number, Array<LittleCalendarEventMetaT>>,
) => {
    const weekStartUnixTimestamp = getUnixTimestamp(weekStart);
    const weekEvents = weeklyMap.get(weekStartUnixTimestamp);
    if (weekEvents) {
        weekEvents.push(event);
    } else {
        weeklyMap.set(weekStartUnixTimestamp, [event]);
    }
};

export const filterCanceledReservations = (dayLectures: LittleCalendarEventMetaT[]) => {
    const dayLecturesWithoutCanceled = dayLectures.filter(lectureEvent => {
        const eventsByReservationWeDontWantToShow = [
            reservationStates.CANCELED,
            reservationStates.CANCELED_WAITLIST,
            reservationStates.CANCELED_WAITLIST_BY_OFFICE,
            reservationStates.CANCELED_BY_OFFICE,
        ];
        if (lectureEvent?.myReservation) {
            const { myReservation } = lectureEvent;
            return !eventsByReservationWeDontWantToShow.includes(myReservation.reservationState);
        }
        // else he is a lecturer
        return true;
    });
    return dayLecturesWithoutCanceled;
};

export const getWorkshopsDataByPermissions = (officeWorkshopsData: any, isPublic: boolean) => {
    let workshops = isPublic
        ? officeWorkshopsData?.publicWorkshopsByOfficeId
        : officeWorkshopsData?.privateWorkshopsByOfficeId;

    let upcomingWorkshopsToSort = workshops && workshops.length > 0 ? workshops : [];
    return upcomingWorkshopsToSort;
};
