import { officeUserRoles } from '@fyooga/codebook';
import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { Icon } from '../components/basics/Icon/Icon';
import { APPLICATION_SUBDOMAIN } from '../constants';
import { useEmployeeCurrentHandledOfficeData } from '../hooks/apollo/useEmployeeCurrentHandledOfficeData';
import { useHasUserAccessByOfficeRole } from '../hooks/useHasUserAccessByOfficeRole';
import {
    APP_TAB_KEY_OFFICE,
    SETTINGS_TAB_KEY_CLIENTS,
    SETTINGS_TAB_KEY_ORDERS,
    SETTINGS_TAB_KEY_STATISTICS,
    SETTINGS_TAB_KEY_SUBSCRIPTION_OVERVIEW,
    SETTINGS_TAB_KEY_WORKSHOPS,
} from '../router/constants';
import { primaryColor } from '../theme';

const SidebarMenuItem = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 12px;
`;

export const SidebarMobileMenu = ({
    mobile = false,
    closeMobileMenuDrawer,
    officeId,
}: {
    mobile?: boolean;
    closeMobileMenuDrawer?: () => void;
    officeId?: number;
}) => {
    const { t } = useTranslation();

    const employeeCurrentHandledOfficeData = useEmployeeCurrentHandledOfficeData(officeId);

    const currentHandledOfficeId = employeeCurrentHandledOfficeData?.officeId;

    const {
        hasAccess: canUserAccessSettingsForRoleManagerOrSuperadmin,
        loading: loadingCanUserAccessSettingsForRoleManagerOrSuperadmin,
    } = useHasUserAccessByOfficeRole(currentHandledOfficeId, [officeUserRoles.MANAGER, officeUserRoles.SUPERADMIN]);
    const {
        hasAccess: canUserAccessSettingsForSuperadminRole,
        loading: loadingCanUserAccessSettingsForSuperadminRole,
    } = useHasUserAccessByOfficeRole(currentHandledOfficeId, [officeUserRoles.SUPERADMIN]);

    const allCheckHasAccessLoaded =
        !loadingCanUserAccessSettingsForRoleManagerOrSuperadmin && !loadingCanUserAccessSettingsForSuperadminRole;

    const handleNavLinkClick = () => {
        if (mobile && closeMobileMenuDrawer) {
            closeMobileMenuDrawer();
        }
    };

    const navLinkProps = {
        className: 'sidebar-menu__item',
        activeClassName: 'sidebar-menu__item-selected',
        onClick: handleNavLinkClick,
    };

    const iconSize = mobile ? 20 : 40;

    return (
        <>
            {employeeCurrentHandledOfficeData && currentHandledOfficeId && allCheckHasAccessLoaded && (
                <>
                    {/*
                    <NavLink exact to={`/${APPLICATION_SUBDOMAIN}`} {...navLinkProps}>
                        {mobile ? (
                            <p>{t('sidebar.dashboard')}</p>
                        ) : (
                            <Tooltip title={t('sidebar.dashboard')}>
                                <Icon icon="Workspace" size={iconSize} iconColor={primaryColor} />
                            </Tooltip>
                        )}
                    </NavLink>
                    */}
                    <NavLink
                        exact
                        to={`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData.domain}`}
                        {...navLinkProps}
                    >
                        {mobile ? (
                            <SidebarMenuItem>
                                <Icon icon="Calendar1" size={iconSize} iconColor={primaryColor} />
                                <span>{t('sidebar.calendar')}</span>
                            </SidebarMenuItem>
                        ) : (
                            <Tooltip title={t('sidebar.calendar')}>
                                <Icon icon="Calendar" size={iconSize} iconColor={primaryColor} />
                            </Tooltip>
                        )}
                    </NavLink>
                    {canUserAccessSettingsForRoleManagerOrSuperadmin && (
                        <NavLink
                            exact
                            to={`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData.domain}/${SETTINGS_TAB_KEY_WORKSHOPS}`}
                            {...navLinkProps}
                        >
                            {mobile ? (
                                <SidebarMenuItem>
                                    <Icon icon="Yoga" size={iconSize} iconColor={primaryColor} />
                                    <span>{t('sidebar.workshops')}</span>
                                </SidebarMenuItem>
                            ) : (
                                <Tooltip title={t('sidebar.workshops')}>
                                    <Icon icon="Yoga" size={iconSize} iconColor={primaryColor} />
                                </Tooltip>
                            )}
                        </NavLink>
                    )}
                    {canUserAccessSettingsForRoleManagerOrSuperadmin && (
                        <>
                            <NavLink
                                exact
                                to={`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData.domain}/${SETTINGS_TAB_KEY_CLIENTS}`}
                                {...navLinkProps}
                            >
                                {mobile ? (
                                    <SidebarMenuItem>
                                        <Icon icon="Clients" size={iconSize} iconColor={primaryColor} />
                                        <span>{t('sidebar.clients')}</span>
                                    </SidebarMenuItem>
                                ) : (
                                    <Tooltip title={t('sidebar.clients')}>
                                        <Icon icon="Clients" size={iconSize} iconColor={primaryColor} />
                                    </Tooltip>
                                )}
                            </NavLink>
                            <NavLink
                                to={`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData.domain}/${SETTINGS_TAB_KEY_ORDERS}`}
                                {...navLinkProps}
                            >
                                {mobile ? (
                                    <SidebarMenuItem>
                                        <Icon icon="Orders1" size={iconSize} iconColor={primaryColor} />
                                        <span>{t('sidebar.orders')}</span>
                                    </SidebarMenuItem>
                                ) : (
                                    <Tooltip title={t('sidebar.orders')}>
                                        <Icon icon="Orders" size={iconSize} iconColor={primaryColor} />
                                    </Tooltip>
                                )}
                            </NavLink>
                        </>
                    )}
                    {canUserAccessSettingsForSuperadminRole && (
                        <>
                            <NavLink
                                to={`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData.domain}/${SETTINGS_TAB_KEY_SUBSCRIPTION_OVERVIEW}`}
                                {...navLinkProps}
                            >
                                {mobile ? (
                                    <SidebarMenuItem>
                                        <Icon icon="Subscriptions" size={iconSize} iconColor={primaryColor} />
                                        <span>{t('sidebar.subscription')}</span>
                                    </SidebarMenuItem>
                                ) : (
                                    <Tooltip title={t('sidebar.subscription')}>
                                        <Icon icon="Subscriptions" size={iconSize} iconColor={primaryColor} />
                                    </Tooltip>
                                )}
                            </NavLink>
                            <NavLink
                                to={`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData.domain}/${SETTINGS_TAB_KEY_STATISTICS}/revenues`}
                                {...navLinkProps}
                            >
                                {mobile ? (
                                    <SidebarMenuItem>
                                        <Icon icon="Statistics" size={iconSize} iconColor={primaryColor} />
                                        <span>{t('sidebar.statistics')}</span>
                                    </SidebarMenuItem>
                                ) : (
                                    <Tooltip title={t('sidebar.statistics')}>
                                        <Icon icon="Statistics" size={iconSize} iconColor={primaryColor} />
                                    </Tooltip>
                                )}
                            </NavLink>
                        </>
                    )}
                    {canUserAccessSettingsForRoleManagerOrSuperadmin && (
                        <NavLink
                            to={`/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${employeeCurrentHandledOfficeData.domain}/settings`}
                            {...navLinkProps}
                        >
                            {mobile ? (
                                <SidebarMenuItem>
                                    <Icon icon="SettingsGears" size={iconSize} iconColor={primaryColor} />
                                    <span>{t('sidebar.settings')}</span>
                                </SidebarMenuItem>
                            ) : (
                                <Tooltip title={t('sidebar.settings')}>
                                    <Icon icon="SettingsGears" size={iconSize} iconColor={primaryColor} />
                                </Tooltip>
                            )}
                        </NavLink>
                    )}
                </>
            )}
        </>
    );
};
