import { ArrowDownOutlined, HomeOutlined } from '@ant-design/icons';
import { useQuery, useReactiveVar } from '@apollo/client';
import { fyoogaQueries } from '@fyooga/graphql-requests';
import { Drawer, Tooltip } from 'antd';
import React, { useMemo, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Logo } from '../../components/basics/Logo';
import { DisabledLinkLikeAUnderline, LinkAUnderline } from '../../components/LinkAUnderline';
import { CurrentStudioName } from '../../components/ui/CurrentStudioName';
import { APPLICATION_SUBDOMAIN } from '../../constants';
import { currentPublicViewStudioVar, isNetworkErrorVar, userIdVar } from '../../lib/apollo/cache';
import { secondaryColor } from '../../theme';
import { OfficeMenu } from '../menu';
import { ExpiredSubscriptionOverview } from '../menu/ExpiredSubscriptionOverview';
import { SidebarMobileMenu } from '../SidebarMobileMenu';
import { HeaderMobileWrapper } from './HeaderMobileWrapper';

const MenuContainer = styled.div`
    display: none;

    @media (max-width: 767px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
    }
`;

export const AuthorizedHeader = ({ isSidebarVisible }: { isSidebarVisible: boolean }) => {
    const { t } = useTranslation();
    let location = useLocation();

    const currentPublicViewStudio = useReactiveVar<any>(currentPublicViewStudioVar);
    const userId = useReactiveVar(userIdVar);
    const isNetworkError = useReactiveVar(isNetworkErrorVar);
    const [mobileMenuDrawerVisible, setMobileMenuDrawerVisible] = useState(false);

    const { data: userEmployersDropdownData } = useQuery(fyoogaQueries.getOfficeEmploymentsByUserIdForDropdown, {
        fetchPolicy: 'cache-and-network',
        variables: {
            userId,
        },
        skip: !userId,
    });

    const isLocationDashboardOverview =
        location?.pathname === '/app' || location?.pathname === '/app/' || location?.pathname === '/';

    const officeName = useMemo(() => {
        if (
            userEmployersDropdownData?.officeEmploymentsByUserIdForDropdown &&
            userEmployersDropdownData.officeEmploymentsByUserIdForDropdown.length
        ) {
            return (
                <OfficeMenu
                    organisationsEmployeeList={userEmployersDropdownData.officeEmploymentsByUserIdForDropdown}
                />
            );
        } else if (currentPublicViewStudio?.name) {
            return (
                <>
                    <div className="header-separator" />
                    <CurrentStudioName
                        studioName={currentPublicViewStudio.name}
                        studioDomain={currentPublicViewStudio.domain}
                    />
                </>
            );
        }
        return null;
    }, [userEmployersDropdownData, currentPublicViewStudio]);

    const showMobileMenuDrawer = () => {
        setMobileMenuDrawerVisible(true);
    };

    const closeMobileMenuDrawer = () => {
        setMobileMenuDrawerVisible(false);
    };

    return (
        <HeaderMobileWrapper isMobile={isMobile}>
            <div className="public-app-menu">
                {isSidebarVisible && (
                    <div>
                        <MenuContainer onClick={showMobileMenuDrawer}>
                            <svg width={40} height={40} viewBox="0 0 30 30" aria-hidden="true" aria-label="Menu">
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeMiterlimit="10"
                                    strokeWidth="2"
                                    d="M4 7h22M4 15h22M4 23h22"
                                />
                            </svg>
                        </MenuContainer>
                        <Drawer
                            title={
                                userEmployersDropdownData?.officeEmploymentsByUserIdForDropdown &&
                                userEmployersDropdownData.officeEmploymentsByUserIdForDropdown.length && (
                                    <OfficeMenu
                                        organisationsEmployeeList={
                                            userEmployersDropdownData.officeEmploymentsByUserIdForDropdown
                                        }
                                    />
                                )
                            }
                            placement="left"
                            closable={false}
                            onClose={closeMobileMenuDrawer}
                            open={mobileMenuDrawerVisible}
                            width={'70%'}
                        >
                            <SidebarMobileMenu
                                mobile={true}
                                closeMobileMenuDrawer={closeMobileMenuDrawer}
                                officeId={currentPublicViewStudio?.officeId}
                            />
                        </Drawer>
                    </div>
                )}
                <div className="app-menu">
                    <div className="app-headline">
                        <div className="studio-logo">
                            <Logo width={70} color="#888bbb" />
                        </div>
                        {!isMobile ? (
                            <>
                                <div className="header-separator" />
                                {isLocationDashboardOverview ? (
                                    <>
                                        <DisabledLinkLikeAUnderline>
                                            {t('headerMenu.atOverview')}
                                        </DisabledLinkLikeAUnderline>
                                    </>
                                ) : (
                                    <LinkAUnderline
                                        onClick={() => (window.location.href = `/${APPLICATION_SUBDOMAIN}`)}
                                    >
                                        {t('headerMenu.goToOverview')}
                                    </LinkAUnderline>
                                )}
                            </>
                        ) : (
                            <>
                                {isLocationDashboardOverview ? (
                                    <b style={{ marginLeft: '15px' }}>
                                        <DisabledLinkLikeAUnderline>
                                            {t('headerMenu.atOverview')} <ArrowDownOutlined />
                                        </DisabledLinkLikeAUnderline>
                                    </b>
                                ) : (
                                    <div
                                        style={{
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => (window.location.href = `/${APPLICATION_SUBDOMAIN}`)}
                                    >
                                        <Tooltip title={t('headerMenu.goToOverview')} mouseEnterDelay={0.5}>
                                            <HomeOutlined
                                                style={{
                                                    fontSize: '21.9px',
                                                    color: secondaryColor,
                                                }}
                                            />
                                        </Tooltip>
                                    </div>
                                )}
                            </>
                        )}
                        {officeName}
                    </div>
                    {!isNetworkError && <ExpiredSubscriptionOverview />}
                </div>
            </div>
        </HeaderMobileWrapper>
    );
};
