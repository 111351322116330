import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { defaultLineColor } from '../../theme';
import { MAX_WIDTH_MIDDLE_CONTAINER } from './BottomReservationButtonForPublicEvent';

type PageAlign = 'center' | 'left';

export const PageContainer = styled.div<{
    withBorder: boolean;
    withPaddingTop: boolean;
    align: PageAlign;
}>`
    display: flex;
    padding: ${props => (props.withPaddingTop ? '20px 5px 20px 5px' : '0px 5px 20px 5px')};
    flex-direction: column;
    border-top: ${props => (props.withBorder ? `1px solid ${defaultLineColor}` : 0)};
    align-items: ${props => `${props.align}`};
`;

export type PageProps = {
    withBorder?: boolean;
    withPaddingTop?: boolean;
    withMiddleContainer?: boolean;
    maxWidth?: number | 'none';
    withTableContainer?: boolean;
    headerTitle?: string | null;
    align?: PageAlign;
    children: ReactNode;
};

export const Page: FC<PageProps> = ({
    withPaddingTop = true,
    withBorder = true,
    withMiddleContainer = false,
    maxWidth = MAX_WIDTH_MIDDLE_CONTAINER,
    withTableContainer = false,
    headerTitle,
    align = 'center',
    children,
}) => {
    // Jak babu pridala do headeru shadow, tak se to s tim tluce, tak zatim vzdy bez border
    withBorder = false;

    return (
        <PageContainer withBorder={withBorder} withPaddingTop={withPaddingTop} align={align}>
            {headerTitle && <h2 className="page-container--heading settings-heading">{headerTitle}</h2>}
            {withMiddleContainer || withTableContainer ? (
                <div
                    style={{
                        ...(withMiddleContainer && {
                            maxWidth: `${maxWidth}px`,
                        }),
                    }}
                    className={withMiddleContainer ? 'page-container-middle' : 'page-container-table'}
                >
                    {children}
                </div>
            ) : (
                children
            )}
        </PageContainer>
    );
};
