import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { zIndexModal } from '../../constants';
import { borderRadiusBase } from '../../theme';
import { OfficeT } from '../../types/office';
import { FreeTariffDisabledWrapper } from '../FreeTariffDisabledWrapper';

export const MAX_WIDTH_MIDDLE_CONTAINER = 1024;

export const BottomReservationButtonForPublicEventWrapperContainer = styled.div`
    position: fixed;
    width: ${MAX_WIDTH_MIDDLE_CONTAINER}px;
    bottom: 10px;
    left: 1rem;
    right: 1rem;
    margin: 0 auto;
    /* bring your own prefixes */
    background-color: whitesmoke;
    box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: ${borderRadiusBase};
    border: 1px solid whitesmoke;
    z-index: ${zIndexModal.bottomReservationButtonForEvent};
    padding: 1rem 2rem;

    @media (max-width: 767px) {
        width: 100%;
        left: 0;
        right: 0;
    }
`;

export const BottomReservationButtonForPublicEventWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const BottomReservationButtonForPublicEvent = ({
    children,
    isCourse = false,
    office,
}: {
    children: ReactNode;
    isCourse?: boolean;
    office: OfficeT;
}) => {
    return (
        <BottomReservationButtonForPublicEventWrapperContainer className="toDynamicHeight">
            <BottomReservationButtonForPublicEventWrapper>
                <div style={{ width: '100%' }}>
                    {isCourse ? (
                        <FreeTariffDisabledWrapper
                            office={office}
                            doodletipWidth="small"
                            withLinkToAdminSubscription={false}
                        >
                            {children}
                        </FreeTariffDisabledWrapper>
                    ) : (
                        <>{children}</>
                    )}
                </div>
            </BottomReservationButtonForPublicEventWrapper>
        </BottomReservationButtonForPublicEventWrapperContainer>
    );
};
