import { useReactiveVar } from '@apollo/client';
import React from 'react';

import { CurrentStudioName } from '../../components/ui/CurrentStudioName';
import { useEmployeeCurrentHandledOfficeData } from '../../hooks/apollo/useEmployeeCurrentHandledOfficeData';
import { currentPublicViewStudioVar } from '../../lib/apollo/cache';
import { StudioSwitcher } from './StudioSwitcher';

export const OfficeMenu = ({ organisationsEmployeeList }) => {
    const currentPublicViewStudio = useReactiveVar(currentPublicViewStudioVar);

    const employeeCurrentHandledOfficeData = useEmployeeCurrentHandledOfficeData(currentPublicViewStudio?.officeId);

    if (organisationsEmployeeList.length) {
        if (organisationsEmployeeList.length > 1) {
            return (
                <>
                    <div className="header-separator" />
                    <StudioSwitcher organisationsEmployeeList={organisationsEmployeeList} />
                </>
            );
        } else if (
            organisationsEmployeeList.length === 1 &&
            (employeeCurrentHandledOfficeData || currentPublicViewStudio)
        ) {
            const studioName = employeeCurrentHandledOfficeData?.name || currentPublicViewStudio?.name;
            const studioDomain = employeeCurrentHandledOfficeData?.domain || currentPublicViewStudio?.domain;

            return (
                <>
                    <div className="header-separator" />
                    <CurrentStudioName studioName={studioName} studioDomain={studioDomain} />
                </>
            );
        }
    }

    return null;
};
