import { useQuery, useReactiveVar } from '@apollo/client';
import { officeEmployeePermissions } from '@fyooga/codebook';
import { fyoogaQueries } from '@fyooga/graphql-requests';
import { useMemo } from 'react';

import { userIdVar } from '../lib/apollo/cache';
import { useLoggedIn } from './apollo/useLoggedIn';

export const useHasUserAccessByOfficePermissions = (officeId: number, requiredBitPermission: number) => {
    const isLoggedIn = useLoggedIn();
    const userId = useReactiveVar(userIdVar);

    const { loading: userOfficeEmployerDataLoading, data: userOfficeEmployerData } = useQuery(
        fyoogaQueries.getEmploymentByUserIdAndOfficeId,
        {
            fetchPolicy: 'cache-and-network',
            variables: {
                userId,
                officeId,
            },
            skip: !(userId && officeId),
        },
    );

    const hasAccess = useMemo(() => {
        if (!isLoggedIn) {
            return false;
        }

        if (!userOfficeEmployerDataLoading && userOfficeEmployerData?.employmentByUserIdAndOfficeId) {
            const { organisationOffices } = userOfficeEmployerData.employmentByUserIdAndOfficeId;

            const [office] = organisationOffices;

            const officeMemberBitPermission =
                office && office?.role ? officeEmployeePermissions?.[office.role]?.bitPermissions : 0;
            // If the result is not 0 (zero) then the single bitmask value (user's access) is present in the sum of the bitmask (required access) and user can access
            // eslint-disable-next-line no-bitwise
            const result = officeMemberBitPermission & requiredBitPermission;
            if (result === 0) {
                return false;
            } else {
                return true;
            }
        }
        return false;
    }, [isLoggedIn, requiredBitPermission, userOfficeEmployerData, userOfficeEmployerDataLoading]);

    return {
        loading: userOfficeEmployerDataLoading,
        hasAccess,
    };
};
