import React from 'react';
import { useTranslation } from 'react-i18next';

import LOCAL_STORAGE_KEYS from '../../enums/localStorageKeys';
import { useLocalizedZenamuLandingPageHost } from '../../hooks/useLocalizedZenamuLandingPageHost';
import { newTariffInfoSeenVar } from '../../lib/apollo/cache';
import { InteractiveButton } from '../buttons';
import { ModalWrapper } from './ModalWrapper';

interface NewTariffsModalProps {
    visible: boolean;
    onCloseModal: () => void;
}

export const NewTariffsModal = ({ visible, onCloseModal }: NewTariffsModalProps) => {
    const { t } = useTranslation();
    const landingPageHost = useLocalizedZenamuLandingPageHost();

    const handleClickIAmNotInterested = () => {
        newTariffInfoSeenVar(true);
        localStorage.setItem(LOCAL_STORAGE_KEYS.NEW_TARIFF_INFO_SEEN, '1');
    };

    return (
        <ModalWrapper
            title={t('subscriptionNs:changes')}
            closable
            destroyOnClose
            visible={visible}
            onClose={() => {
                handleClickIAmNotInterested();
                onCloseModal();
            }}
            zIndex={100001} // bigger than reactour
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <p>{t('subscriptionNs:changesDescription1')}</p>
                <p>{t('subscriptionNs:changesDescription2')}</p>
            </div>
            <InteractiveButton
                block
                value={t('more')}
                onClick={event => {
                    event.preventDefault();
                    handleClickIAmNotInterested();
                    onCloseModal();
                    window.open(`${landingPageHost}/new-pricing/`, '_blank');
                }}
            />
        </ModalWrapper>
    );
};
