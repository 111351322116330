import 'moment/locale/cs';
import 'moment/locale/en-gb';
import 'moment/locale/sk';
import 'moment/locale/hu';
import 'moment/locale/pl';
import 'react-lazy-load-image-component/src/effects/blur.css';

import '../App.less';

import * as Sentry from '@sentry/react';
import { ConfigProvider } from 'antd';
import csCZ from 'antd/lib/locale/cs_CZ';
import enUS from 'antd/lib/locale/en_US';
import huHU from 'antd/lib/locale/hu_HU';
import plPL from 'antd/lib/locale/pl_PL';
import skSK from 'antd/lib/locale/sk_SK';
import i18next from 'i18next';
import moment from 'moment';
import React, { useEffect } from 'react';
import { I18nextProvider } from 'react-i18next';

import App from '../App';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { ZenamuLayout } from '../components/ZenamuLayout';
import { useAppLocale } from '../hooks/apollo/useAppLocale';
import { useLoggedIn } from '../hooks/apollo/useLoggedIn';
import { useScript } from '../hooks/useScript';
import { AuthorizedLayoutComponent } from '../layout/AuthorizedLayoutComponent';
import { PublicLayoutComponent } from '../layout/PublicLayoutComponent';
import { clearEmployeeCurrentHandledOrganisationOffice } from '../lib/apollo/cache';
import { ErrorNetworkProblems } from '../pages/errors';
import { CookiesProvider } from './CookiesProvider';

// import 'antd/dist/antd.css';

const locales = {
    cs: csCZ,
    enUS,
    sk: skSK,
    hu: huHU,
    pl: plPL,
};

export const AppProviders = () => {
    const userAppLocale = useAppLocale();
    const isLoggedIn = useLoggedIn();

    // with sentry error boundary
    // https://docs.sentry.io/platforms/javascript/guides/react/components/errorboundary/

    moment.locale(userAppLocale);

    const boundaryContent = (
        <ConfigProvider locale={locales[userAppLocale]}>
            <ZenamuLayout>
                <CookiesProvider>
                    <App />
                </CookiesProvider>
            </ZenamuLayout>
        </ConfigProvider>
    );

    return (
        <I18nextProvider i18n={i18next}>
            {process.env.NODE_ENV === 'production' ? (
                <Sentry.ErrorBoundary
                    fallback={
                        isLoggedIn ? (
                            <AuthorizedLayoutComponent isSidebarVisible={false}>
                                <ErrorNetworkProblems />
                            </AuthorizedLayoutComponent>
                        ) : (
                            <PublicLayoutComponent>
                                <ErrorNetworkProblems />
                            </PublicLayoutComponent>
                        )
                    }
                >
                    {boundaryContent}
                </Sentry.ErrorBoundary>
            ) : (
                <ErrorBoundary isLoggedIn={isLoggedIn}>{boundaryContent}</ErrorBoundary>
            )}
        </I18nextProvider>
    );
};
