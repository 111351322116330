import { useQuery } from '@apollo/client';
import { permissions } from '@fyooga/codebook';
import { fyoogaQueries } from '@fyooga/graphql-requests';
import { useMemo } from 'react';

import { OrganisationT } from '../types/organisation';
import { LicensePaymentT, SmsPackagePaymentT } from '../types/subscription';
import { useHasUserAccessByOfficePermissions } from './useHasUserAccessByOfficePermissions';
import { useSubscribeGetOrganisationById } from './useSubscribeGetOrganisationById';
import { useSubscribeGetOrganisationLicensePaymentsById } from './useSubscribeGetOrganisationLicensePaymentsById';

export interface OrganisationLicenseDataT {
    organisation: OrganisationT;
    currentOrganisationLicensePayment: LicensePaymentT | null;
    organisationLicensePayments: LicensePaymentT[];
    organisationSmsPackagesOrders: SmsPackagePaymentT[];
    canUserWriteOrgTariffInfo: boolean;
}

export interface OrganisationLicenseT {
    data: OrganisationLicenseDataT | null;
    loading: boolean;
}

export const useGetOrganisationOfficeLicenseData = (organisationId: number, officeId: number): OrganisationLicenseT => {
    const { loading: loadingCanUserWriteOrgTariffInfo, hasAccess: canUserWriteOrgTariffInfo } =
        useHasUserAccessByOfficePermissions(officeId, permissions.WRITE_ORGANISATION_TARIFF_INFO.bitPermissions);

    const hasAccessToOrgSubscriptionData = !loadingCanUserWriteOrgTariffInfo && canUserWriteOrgTariffInfo;

    const { loading: loadingOrganisation, data: officeOrganisationData } = useSubscribeGetOrganisationById(
        organisationId,
        !hasAccessToOrgSubscriptionData,
    );

    const { loading: loadingLicensePayments, data: licensePaymentsData } =
        useSubscribeGetOrganisationLicensePaymentsById(organisationId, officeId);

    const { loading: loadingSmsPackagesPayments, data: smsPackagesPaymentsData } = useQuery(
        fyoogaQueries.getOrganisationSmsPackagesOrdersById,
        {
            fetchPolicy: 'network-only',
            variables: {
                officeId,
                organisationId,
            },
            skip: !hasAccessToOrgSubscriptionData,
        },
    );

    const organisationOfficeLicense = useMemo(() => {
        if (
            loadingCanUserWriteOrgTariffInfo ||
            loadingOrganisation ||
            loadingLicensePayments ||
            loadingSmsPackagesPayments
        ) {
            return {
                loading: true,
                data: null,
            };
        } else {
            let dataToSet: OrganisationLicenseDataT = {
                organisation: officeOrganisationData?.organisationById,
                organisationLicensePayments: [],
                organisationSmsPackagesOrders: [],
                currentOrganisationLicensePayment: null,
                canUserWriteOrgTariffInfo,
            };
            if (licensePaymentsData?.organisationLicensePaymentsById) {
                dataToSet = {
                    ...dataToSet,
                    organisationLicensePayments: licensePaymentsData.organisationLicensePaymentsById,
                    currentOrganisationLicensePayment:
                        officeOrganisationData?.organisationById?.lastOrganisationLicensePayment ?? null,
                };
            }

            if (smsPackagesPaymentsData?.organisationSmsPackagesOrdersById) {
                dataToSet = {
                    ...dataToSet,
                    organisationSmsPackagesOrders: smsPackagesPaymentsData?.organisationSmsPackagesOrdersById,
                };
            }

            return {
                data: dataToSet,
                loading: false,
            };
        }
    }, [
        officeOrganisationData,
        licensePaymentsData,
        loadingOrganisation,
        loadingLicensePayments,
        canUserWriteOrgTariffInfo,
        loadingCanUserWriteOrgTariffInfo,
        loadingSmsPackagesPayments,
        smsPackagesPaymentsData,
    ]);

    return organisationOfficeLicense;
};
