import 'vanilla-cookieconsent/dist/cookieconsent.css';
import 'vanilla-cookieconsent';

import {
    cookieGuiOptions,
    getCookiePolicyContent,
    handleAnalyticsCategory,
    handleTargetingCategory,
    onChange,
    onFirstAction,
} from '@fyooga/cookies';
import React, { ReactNode, createContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

import { config } from '../config';
import { useAppLocale } from '../hooks/apollo/useAppLocale';
import { showErrorToast } from '../lib/toasts';
import { axiosClient } from '../services/axios/api';

export const CookiesContext = createContext({
    cookieConsent: null,
});

// https://orestbida.com/demo-projects/cookieconsent/
// https://www.a-mk.com/cz/cookies/
// https://www.c2cuniversity.org/cookies
export const CookiesProvider = ({ children }: { children: ReactNode }) => {
    const location = useLocation();
    const [cookieConsent, setCookieConsent] = useState<any>();
    const userAppLocale = useAppLocale();

    useEffect(() => {
        if (!cookieConsent && userAppLocale) {
            // @ts-ignore
            const cc = window.initCookieConsent();
            setCookieConsent(cc);

            cc.run({
                current_lang: userAppLocale,
                /**
                 * This function will be executed only once, when the user takes the first action (accept/reject).
                 *
                 * parameters:
                 *
                 * user_preferences: contains the same data provided by the .getUserPreferences() API
                 * cookie: contains the current value of the cookie
                 * @param _user_preferences
                 * @param cookie
                 */
                onFirstAction: async (_user_preferences: any, cookie: any) => {
                    try {
                        await onFirstAction(axiosClient, cc, _user_preferences, cookie);
                    } catch (err: any) {
                        showErrorToast(err.message);
                    }
                },

                /**
                 * This function will be executed:
                 *
                 * at the first moment that consent is given (just like onFirstAction)
                 * after every page load, if consent (accept or "reject" action) has already been given
                 *
                 * parameters:
                 *
                 * cookie: contains the current value of the cookie
                 * @param cookie
                 */
                onAccept: (_cookie: any) => {
                    // load somescript, google analytics ...
                    handleAnalyticsCategory(config.GOOGLE_ANALYTICS_UA, cc);
                    handleTargetingCategory(cc);
                },

                /**
                 * This function will be executed (only if consent has already been given):
                 *
                 * when user changes his preferences (accepts/rejects a cookie category)
                 *
                 * parameters:
                 *
                 * cookie: contains the current value of the cookie
                 * changed_categories: array of categories whose state (accepted/rejected) just changed
                 * @param cookie
                 * @param changed_preferences
                 */
                onChange: async (cookie: any, changed_preferences: any) => {
                    try {
                        await onChange(axiosClient, cc, cookie, changed_preferences);
                    } catch (err: any) {
                        showErrorToast(err.message);
                    }
                    handleAnalyticsCategory(config.GOOGLE_ANALYTICS_UA, cc, true);
                    handleTargetingCategory(cc, true);
                },

                gui_options: cookieGuiOptions,
                languages: {
                    enUS: getCookiePolicyContent('enUS'),
                    cs: getCookiePolicyContent('cs'),
                    sk: getCookiePolicyContent('sk'),
                    hu: getCookiePolicyContent('hu'),
                    pl: getCookiePolicyContent('pl'),
                },
            });
        }
    }, [userAppLocale]);

    useEffect(() => {
        if (cookieConsent && userAppLocale) {
            try {
                cookieConsent.updateLanguage(userAppLocale);
            } catch (err) {
                // it fails from time to time
            }
        }
    }, [cookieConsent, userAppLocale]);

    useEffect(() => {
        if (cookieConsent && cookieConsent.allowedCategory('analytics') && process.env.NODE_ENV === 'production') {
            ReactGA.set({ page: location.pathname });
            ReactGA.send('pageview');
        }
    }, [cookieConsent, location]);

    return (
        <CookiesContext.Provider
            value={{
                cookieConsent,
            }}
        >
            {children}
        </CookiesContext.Provider>
    );
};
