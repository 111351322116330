import { useReactiveVar } from '@apollo/client';
import i18next from 'i18next';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';

import { CacheBustingRefreshModal } from './components/ui/CacheBustingRefreshModal';
import { appLocales } from './constants';
import { AuthContext } from './context/AuthProvider';
import { WorkInProgressProvider } from './context/WorkInProgressProvider';
import { useAppLocale } from './hooks/apollo/useAppLocale';
import { useLoggedIn } from './hooks/apollo/useLoggedIn';
import { AuthorizedLayout, PublicLayout } from './layout';
import { setAppLocale, versionUpdateNeededVar } from './lib/apollo/cache';
import { FormatterProvider, FormatterProviderConfig } from './lib/formatters';
import { theme } from './theme';

const App = () => {
    const [versionUpdateNeededVisible, setVersionUpdateNeededVisible] = useState(false);
    const { userData } = useContext(AuthContext);
    const versionUpdateNeeded = useReactiveVar(versionUpdateNeededVar);
    const isLoggedIn = useLoggedIn();
    const userAppLocale = useAppLocale();

    // load locale for logged-in user and change locale
    useEffect(() => {
        if (isLoggedIn && userData) {
            const handleChangeLanguage = async (lang: string) => {
                await i18next.changeLanguage(lang);
                setAppLocale(lang);
            };

            const {
                userConfig: { lang },
            } = userData;
            if (lang) {
                handleChangeLanguage(lang);
            }
        }
    }, [userData, isLoggedIn]);

    useEffect(() => {
        switch (userAppLocale) {
            case appLocales.CS: {
                moment.locale(appLocales.CS);
                break;
            }
            case appLocales.SK: {
                moment.locale(appLocales.SK);
                break;
            }
            case appLocales.HU: {
                moment.locale(appLocales.HU);
                break;
            }
            case appLocales.PL: {
                moment.locale(appLocales.PL);
                break;
            }
            case appLocales.EN: {
                moment.locale('en');
                break;
            }
            default: {
                moment.locale('en');
                break;
            }
        }
    }, [userAppLocale]);

    useEffect(() => {
        if (versionUpdateNeeded) {
            setVersionUpdateNeededVisible(true);
        }
    }, [versionUpdateNeeded]);

    const formatterConfig: FormatterProviderConfig = useMemo(() => {
        switch (userAppLocale) {
            case appLocales.CS: {
                return {
                    locale: 'cs',
                };
            }
            case appLocales.SK: {
                return {
                    locale: 'sk',
                };
            }
            case appLocales.HU: {
                return {
                    locale: 'hu',
                };
            }
            case appLocales.PL: {
                return {
                    locale: 'pl',
                };
            }
            default: {
                return {
                    locale: 'en',
                };
            }
        }
    }, [userAppLocale]);

    return (
        <ThemeProvider theme={theme}>
            <IntlProvider locale={formatterConfig.locale} key={formatterConfig.locale}>
                <FormatterProvider config={formatterConfig}>
                    <WorkInProgressProvider>
                        <>
                            {isLoggedIn ? <AuthorizedLayout /> : <PublicLayout />}
                            <CacheBustingRefreshModal
                                visible={versionUpdateNeededVisible}
                                onCloseModal={() => setVersionUpdateNeededVisible(false)}
                            />
                        </>
                    </WorkInProgressProvider>
                </FormatterProvider>
            </IntlProvider>
        </ThemeProvider>
    );
};

export default App;
