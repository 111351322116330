import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { OfficeT } from '../types/office';
import { isTariffFree } from '../util/tariff';
import { FreeTariffDoodletip } from './FreeTariffDoodletip';

export interface FreeTariffDisabledWrapperProps {
    children: ReactNode;
    office: OfficeT;
    withDoodletip?: boolean;
    doodletipWidth?: 'big' | 'small';
    withLinkToAdminSubscription?: boolean;
}

export const FreeTariffWrapperContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
`;

export const FreeTariffDisabledWrapper = ({
    children,
    office,
    withDoodletip = true,
    doodletipWidth = 'big',
    withLinkToAdminSubscription = true,
}: FreeTariffDisabledWrapperProps) => {
    const { domain, orgTariffMeta } = office;

    const { tariffName } = orgTariffMeta;

    const isFreeTariff = isTariffFree(tariffName);

    if (isFreeTariff) {
        return (
            <>
                {withDoodletip && (
                    <>
                        {doodletipWidth === 'big' ? (
                            <FreeTariffWrapperContainer>
                                <div className="page-container-middle">
                                    <FreeTariffDoodletip
                                        domain={domain}
                                        withLinkToAdminSubscription={withLinkToAdminSubscription}
                                    />
                                </div>
                            </FreeTariffWrapperContainer>
                        ) : (
                            <FreeTariffDoodletip
                                domain={domain}
                                withLinkToAdminSubscription={withLinkToAdminSubscription}
                            />
                        )}
                    </>
                )}
                <div
                    style={{
                        pointerEvents: 'none',
                        fill: '#fff',
                        opacity: 0.5,
                    }}
                >
                    {children}
                </div>
            </>
        );
    }

    return <>{children}</>;
};
