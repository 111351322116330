import { useQuery, useReactiveVar } from '@apollo/client';
import { fyoogaQueries } from '@fyooga/graphql-requests';
import React, { createContext, useEffect, useState } from 'react';

import { useLoggedIn } from '../hooks/apollo/useLoggedIn';
import { userIdVar } from '../lib/apollo/cache';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const userId = useReactiveVar(userIdVar);
    const isLoggedIn = useLoggedIn();
    const [shouldSkip, setShouldSkip] = useState(!(userId && isLoggedIn));

    useEffect(() => {
        if (userId && isLoggedIn) {
            setShouldSkip(false);
        }
    }, [userId, isLoggedIn]);

    const { data: userData, loading: loadingUserData } = useQuery(fyoogaQueries.getUserById, {
        variables: {
            userId,
        },
        skip: shouldSkip,
    });

    return (
        <AuthContext.Provider
            value={{
                userData: userData?.userById ?? null,
                loadingUserData,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
