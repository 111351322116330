import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { APPLICATION_SUBDOMAIN } from '../../constants';
import { APP_TAB_KEY_OFFICE } from '../../router/constants';

interface CurrentStudioNameProps {
    studioName: string;
    studioDomain: string;
}

export const CurrentStudioName = ({ studioName, studioDomain }: CurrentStudioNameProps) => {
    const { t } = useTranslation();

    return (
        <Link
            to={{
                pathname: `/${APPLICATION_SUBDOMAIN}/${APP_TAB_KEY_OFFICE}/${studioDomain}`,
            }}
        >
            <div className="current-studio-name">{studioName}</div>
        </Link>
    );
};
